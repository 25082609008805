<template>
    <div>
        <b-modal :no-close-on-backdrop=true size='xl' @close='closed()' hide-footer v-model="modalShow" title="Finish Appointment">
            <FinishAppointmentForm @saved='saved' :appointment='appointment'></FinishAppointmentForm>        
        </b-modal>
    </div>
</template>

<script>
import FinishAppointmentForm from '@/components/FinishAppointmentForm';
import BaseModal from '@/components/Modals/BaseModal';

export default {
    extends: BaseModal,
    components: {FinishAppointmentForm},
    props: ['appointment']
}
</script>